<template>
    <div class="page-admin-help">
        <page-header headline="Systemopdateringer"></page-header>
        <p class="mx-3">Læs om seneste opdateringer herunder</p>
        <div class="content-wrapper">
            <div class="main-content mb-5">
                <h3 class="align-items-center flex mb-3 ml-3">v.24.6.2 - lanceret den 09-09-2024</h3>
                <Accordion>
                    <AccordionTab header="Opdatering af Elev Stamdata-byggeklods">
                        <div class="px-3">
                            <p>
                                Tidligere blev elevens stamdatafelter ikke inkluderet i det afsluttede dokument – de blev kun opdateret i Skoleadmin. Nu sørger vi for, at disse felter både gemmes og vises i det endelige dokument.
                            </p>
                        </div>
                    </AccordionTab>
                </Accordion>
                <h3 class="align-items-center flex mb-3 ml-3 mt-6">v.24.6.1 - lanceret den 04-09-2024</h3>
                <Accordion>
                    <AccordionTab header="Opdatering af import af kontakter">
                        <div class="px-3">
                            <p>
                                Vi har forbedret importen af kontakter med mere detaljeret information og bedre sammenligningsmuligheder. Desuden er Skoleadmin-uploadformatet blevet udvidet til at understøtte flere felter, så det bedre matcher jeres behov og kan bruges sammen med flere systemer.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Opdatering af underskrifter">
                        <div class="px-3">
                            <p>
                                Vi har opdateret modulet til elektronisk underskrift med touch/mus. Underskriftsfeltet er blevet udvidet, og tegneoplevelsen forbedret, så det nu er nemmere at underskrive.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Antivirusscanning af uploadede dokumenter">
                        <div class="px-3">
                            <p>
                                Når en bruger uploader en fil, bliver den automatisk scannet for virus. I kan nu også få adgang til information om, hvorvidt en fil er blevet scannet, og om der er fundet nogen trusler. Der er tilføjet en ekstra kolonne, "Antivirusscanning", til de uploadede filer.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Ændret pinkode fra 4 til 6 cifre">
                        <div class="px-3">
                            <p>For at øge sikkerheden for vores brugere har vi ændret længden på pinkoder fra 4 til 6 cifre.</p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Mindre fejlrettelser og design opdateringer">
                        <div class="px-3">
                            <p>Vi har udført små justeringer og forbedringer for at optimere brugeroplevelsen og opdatere designet.</p>
                        </div>
                    </AccordionTab>
                </Accordion>
                <h3 class="align-items-center flex mb-3 ml-3 mt-6">v.24.5.1 - lanceret den 17-07-2024</h3>
                <Accordion>
                    <AccordionTab header="Afslut dokument selvom alle ikke har underskrevet">
                        <div class="px-3">
                            <p>
                                Nu er det muligt at tvinge et dokument til afslutning. Når dokumentet afsluttes og forsegles, vil de personer, der mangler at underskrive, stadig være angivet som underskrivere, men det vil fremgå tydeligt, at de ikke har underskrevet.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Fjern en underskriver fra et dokument">
                        <div class="px-3">
                            <p>
                                Af og til opstår der fejl, og man får måske sendt et bestyrelsesreferat til et medlem, der ikke deltog. Nu er det muligt for jer som skole at fjerne en underskriver fra et dokument.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Slet et dokument på en kontakt (elev)">
                        <div class="px-3">
                            <p>På en kontakt (elev) under afsendte og afsluttede dokumenter kan man nu slette et dokument direkte.</p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Mindre fejlrettelser og design opdateringer">
                        <div class="px-3">
                            <p>Vi har udført små justeringer og forbedringer for at optimere brugeroplevelsen og opdatere designet.</p>
                        </div>
                    </AccordionTab>
                </Accordion>
                <h3 class="align-items-center flex mb-3 ml-3 mt-6"><!--<i class="mr-2 pi pi-verified text-2xl"></i>-->v.24.4.1 - lanceret den 30-06-2024</h3>
                <Accordion>
                    <AccordionTab header="Optimeret menu og navngivning">
                        <div class="px-3">
                            <p>
                                Vi har opdateret menuen ved at ændre rækkefølgen, tilføje nye menupunkter og omdøbe eksisterende. Bag ændringerne ligger naturligvis en hensigt. Vi har justeret rækkefølgen baseret på de mest anvendte menupunkter, så de vigtigste er øverst. Navngivningen er tilpasset efter jeres fremtidige ønsker for bedre at imødekomme jeres behov. De nye menupunkter er blevet tilføjet for at give jer yderligere funktionalitet.
                            </p>
                            <p>
                                Her er en liste over de navneændringer, der er blevet foretaget:<br />
                                - "Send" er blevet ændret til "Send dokument"<br />
                                - "Elever" er blevet ændret til "Kontakter"<br />
                                - "Hold" er blevet ændret til "Gruppe"<br />
                                - "Dokumenter" er blevet ændret til "Skabeloner"
                            </p>
                            <p>Derudover er følgende nye menupunkter blevet introduceret:</p>
                            <p>
                                - Papirkurv<br />
                                - Arkiv
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Papirkurv">
                        <div class="px-3">
                            <p>
                                Papirkurven fungerer som et mellemliggende trin inden permanent sletning eller arkivering. Den tilbyder en ekstra sikkerhed, så du kan gendanne en entitet, hvis den bliver slettet ved en fejl.
                                Hvor lang tid et dokument, samtykke, skabelon, kontakt eller gruppe ligger i papirkurven styres under indstillinger > sletteregler.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Arkiv og sletteregler">
                        <div class="px-3">
                            <p>
                                Skoleadmin kan nu hjælpe dig med at automatisk rydde op vha. sletteregler. Slettereglerne styres under indstillinger og på en gruppe.<br /><br />
                                <strong>Gruppe</strong><br />På en gruppe kan man indstille en slutdato. Når denne dato er overskredet, flyttes gruppen automatisk til papirkurven. Når du opretter en ny gruppe, kan du angive denne dato, som senere kan ændres ved at klikke på de tre prikker ved siden af gruppen.
                                <br /><br />
                                <strong>Indstillinger > Sletteregler > Kontakter</strong><br />Her kan du indstille, hvor mange dage der skal gå, før en kontakt uden gruppe flyttes til papirkurven. En kontakt betragtes som uden gruppe, hvis gruppen slettes og kontakten ikke er medlem af andre grupper.
                                <br /><br />
                                <strong>Indstillinger > Sletteregler > Papirkurv</strong><br />Her kan du indstille, hvor mange dage en slettet entitet skal opbevares i papirkurven, før den slettes permanent. Dokumenter og samtykker overføres til arkivet.
                                <br /><br />
                                <strong>Indstillinger > Sletteregler > Arkiv</strong><br />Her kan du indstille, hvor mange måneder et dokument eller samtykke skal opbevares i arkivet.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Ny byggeklods, upload dokumentation">
                        <div class="px-3">
                            <p>
                                Med "Modtag dokumentation" byggeklodsen har du mulighed for at lade modtagere uploade PDF-filer samt billeder. Dette kan være nyttigt, hvis din skole fx skal ud at rejse og har brug for en kopi af pas, har brug for en udenlandsk forældres årsopgørelse og lignende dokumenter.
                                <br />
                                Du kan vælge det maksimale antal filer, en modtager kan uploade, om upload er obligatorisk, og hvilken type indhold der uploades.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Træk samtykke tilbage">
                        <div class="px-3">
                            <p>
                                Hvis en elev eller forælder ønsker at tilbagekalde deres samtykke, kan dette nemt gøres ved at sende et nyt samtykkeskema, hvor de nye svar vil overskrive de gamle.
                                <br />
                                En ny funktion i Skoleadmin gør det nu også muligt for jer som systembrugere at tilbagekalde samtykke direkte. Hvis en forælder for eksempel ringer og anmoder om det, kan I nu selv gøre det ved at navigere til Samtykker > Se svar og klikke på de 3 prikker.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Optimeret copy/paste i tekst byggeklodsen">
                        <div class="px-3">
                            <p>
                                Når man kopierer tekst fra for eksempel Word til tekst byggeklodsen, kommer der ofte uønsket formatering med. Dette er nu forbedret, så unødvendig formatering fjernes automatisk. Jeres skabeloner vil derfor fremover altid benytte den samme skrifttype.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Hvornår er dokumentet sidst ændret?">
                        <div class="px-3">
                            <p>
                                Vi har tilføjet en ny kolonne til statistikoversigten, opdateret, hvor I kan se, hvornår der er foretaget ændringer i dokumentet. Datoen opdateres også, når I sender en påmindelse ud.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Mulighed for at omdøb en gruppe">
                        <div class="px-3">
                            <p>
                                En lille, men essentiel ændring: Nu kan I opdatere gruppens navn uden at skulle kontakte os 🙂 Klik på de 3 prikker ud for gruppen.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Tilføjet dokument nøgle på alle typer dokumenter (Noget til revisoren)">
                        <div class="px-3">
                            <p>
                                Fremover vil alle typer dokumenter have en dokumentnøgle i højre side på alle sider. Tidligere blev dette kun gjort ved underskrift med MitID.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Log ind med MitID erhverv">
                        <div class="px-3">
                            <p>
                                Nu kan du logge ind med skolens MitID Erhverv i stedet for dit private MitID.
                            </p>
                        </div>
                    </AccordionTab>
                    <AccordionTab header="Fejlrettelse: Forældre uden forældremyndighed">
                        <div class="px-3">
                            <p>
                                Forældre uden forældremyndighed bliver nu ikke importeret ved import fra KOMiT.
                            </p>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { onMounted } from 'vue';
    import { useRoute } from "vue-router"

    const route = useRoute();
    const { hash } = route;

    onMounted(() => {
        if (hash) {
            var ele = document.querySelector(hash);
            if (ele) {
                ele.scrollIntoView({ behavior: 'smooth' });
            }
        }
    })
</script>

<style>
    .p-accordion-header {
        background-color: white;
    }
</style>
